import axios from "axios";

let visitID = localStorage.getItem("vid");
var myAxios = axios.create({
  // baseURL: "http://localhost:4000",
  baseURL: "https://api.ecomtechbd.com",
  headers: { visitID: visitID },
});

export default myAxios;
